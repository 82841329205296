<template>
  <modal
    name="modal-info"
    height="auto"
    :scrollable="true"
    styles="overflow:auto; padding:10px;"
    class="estilo"
  >
    <div class="col-sm-12">
      <h2>Aviso Legal Privacidad</h2>
      <div>
        <div
          class="et_pb_module et_pb_text et_pb_text_0 et_pb_bg_layout_light  et_pb_text_align_left"
          style="box-sizing: border-box; margin: 0px 0px 29.6875px; padding: 0px; border: 0px; outline: 0px; background-image: initial; background-position: center center; background-size: cover; background-repeat: no-repeat; background-attachment: initial; background-origin: initial; background-clip: initial; vertical-align: baseline; text-size-adjust: 100%; overflow-wrap: break-word; animation-timing-function: linear; animation-duration: 0.2s; color: rgb(102, 102, 102); font-family: &quot;Open Sans&quot;, Helvetica, Arial, Lucida, sans-serif;"
        >
          <div
            class="et_pb_text_inner"
            style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; position: relative;"
          >
            <h3
              class="h3_each_static_section"
              style="box-sizing: border-box; margin: 0px; padding: 0px 0px 10px; border: 0px; outline: 0px; background: 0px 0px; font-size: 22px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(51, 51, 51); font-weight: 500; line-height: 1em; font-family: Oswald, Helvetica, Arial, Lucida, sans-serif;"
            >
              Titularidad
            </h3>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              En cumplimiento de lo previsto en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, se informa que el titular de este sitio web es la siguiente entidad:
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; text-align: center;">
              AICAD BUSINESS SCHOOL SL con CIF: B76062199, Empresa inscrita en el Registro Mercantil de las Palmas 30/04/2010 Tomo 1958 libro 0 Folio 116 y con domicilio en con domicilio social en Calle León y Castillo 54, 1º Planta, 35003, Las Palmas de Gran Canaria.
            </p>
            <h3
              class="h3_each_static_section"
              style="box-sizing: border-box; margin: 0px; padding: 0px 0px 10px; border: 0px; outline: 0px; background: 0px 0px; font-size: 22px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(51, 51, 51); font-weight: 500; line-height: 1em; font-family: Oswald, Helvetica, Arial, Lucida, sans-serif;"
            >
              Aceptación del aviso legal
            </h3>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Te recordamos que Aicad ofrece sus productos y servicios a través de la plataforma de Internet websincoste.com (www.websincoste.com), en adelante Aicad, y el usuario que acceda, a la misma, bien a través de un ordenador personal, bien a través de cualquier aplicación desarrollada para “smartphones” o por cualquier otro medio y que, en su caso, utilice las prestaciones ofrecidas por ésta. En consecuencia, el mero acceso a Aicad por cualquier medio implica la aceptación por el usuario de las presentes condiciones y términos de uso. Podrás contactar con nosotros al email info@websincoste.com o al teléfono (+34) 928 35 46 68 para pedir más información.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; text-align: center;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0cm; border: 1pt none windowtext; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-family: &quot;Open Sans&quot;, sans-serif; color: olive;">Fecha de la última revisión: 17 de Abril 2018</span>
            </p>
          </div>
        </div>
        <div
          class="et_pb_module et_pb_text et_pb_text_1 et_pb_bg_layout_light  et_pb_text_align_left"
          style="box-sizing: border-box; margin: 0px 0px 29.6875px; padding: 0px; border: 0px; outline: 0px; background-image: initial; background-position: center center; background-size: cover; background-repeat: no-repeat; background-attachment: initial; background-origin: initial; background-clip: initial; vertical-align: baseline; text-size-adjust: 100%; overflow-wrap: break-word; animation-timing-function: linear; animation-duration: 0.2s; color: rgb(102, 102, 102); font-family: &quot;Open Sans&quot;, Helvetica, Arial, Lucida, sans-serif;"
        >
          <div
            class="et_pb_text_inner"
            style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; position: relative;"
          >
            <h3 style="box-sizing: border-box; margin: 0px; padding: 0px 0px 10px; border: 0px; outline: 0px; background: 0px 0px; font-size: 26px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(51, 51, 51); font-weight: 500; line-height: 1em; font-family: Oswald, Helvetica, Arial, Lucida, sans-serif;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; font-size: large; vertical-align: baseline; text-size-adjust: 100%;"><span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; font-size: 18px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">AVISO LEGAL DEL PORTAL DE AICAD BUSINESS SCHOOL</span></span>
            </h3>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Las cláusulas que a continuación se relacionan regulan las condiciones de uso del Portal Web de Internet “www.websincoste.com” (en adelante, el Portal) de Aicad Business School, S.L.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              La expresión “usuario” comprende cualquier persona que acceda al Portal, ya sea directamente o bien desde cualquier otro sitio de Internet. Las condiciones generales que regulan el uso del Portal son las siguientes:
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">1.- GENÉRICAS.</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Los servicios ofrecidos por Aicad Business School, S.L. en el presente sitio de Internet se regulan por las cláusulas contenidas en las presentes condiciones (sin perjuicio de los demás avisos legales y condiciones que figuran en el Portal).
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Aicad Business School, S.L. se reserva el derecho a modificar, total o parcialmente, estas condiciones generales, siendo de aplicación las nuevas que acuerde desde el momento de su inserción en la página.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">2.- IDENTIFICACION Y RAZÓN SOCIAL DE LA EMPRESA OFERENTE.</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              AICAD BUSINESS SCHOOL SL con CIF: B76062199, Empresa inscrita en el Registro Mercantil de las Palmas 30/04/2010 Tomo 1958 libro 0 Folio 116 y con domicilio en Calle León y Castillo 54, 1º Planta, 53003, Las Palmas de Gran Canaria, cuyo nombre comercial es Aicad Business School, S.L.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Para cualquier consulta, sugerencia o reclamación el usuario puede dirigirse a las oficinas de nuestra empresa sitas en la dirección mencionada anteriormente, o bien a la dirección de correo electrónico&nbsp;<a
                href="mailto:info@websincoste.com"
                style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(0, 160, 223); text-decoration-line: none;"
              >info@websincoste.com</a>, mediante teléfono: +34 928 35 46 68 o usando el&nbsp;<a
                href="https://www.websincoste.com/contacta-con-nosotros/"
                style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(0, 160, 223); text-decoration-line: none;"
              >formulario de contacto</a>.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">3.- CONDICIONES DE ACCESO DEL USUARIO.</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              El usuario se obliga a usar la información contenida en el presente sitio de Internet para las finalidades propias del Portal exclusivamente, en concreto para la finalidad de obtención de información sobre los servicios ofrecidos y realización de sugerencias y a no realizar directa o indirectamente ningún tipo de explotación comercial diferente a los usos autorizados.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              El usuario de la página web tendrá derecho al acceso libre y gratuito a la información pública recogida en la misma, si bien Aicad Business School, S.L. se reserva el derecho a restringir el acceso a determinadas secciones y servicios del Portal a los clientes registrados.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Aicad Business School, S.L. prohíbe terminantemente el uso de los elementos restringidos a determinado grupo de usuarios a los usuarios no autorizados por nombre y contraseña.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Aicad Business School, S.L. ha puesto a disposición de los usuarios autorizados a acceder a la zona privada de la página web un nombre y contraseña cuya custodia compete única y exclusivamente al usuario. En consecuencia, Aicad Business School, S.L. no es responsable bajo ninguna circunstancia del uso indebido de los nombres de usuario y contraseñas.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">4.- PROPIEDAD INTELECTUAL E INDUSTRIAL.</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Los derechos relativos a la propiedad industrial e intelectual (a título enunciativo y no limitativo las marcas, logotipos, textos, fotografías, iconos, imágenes, etc., así como el diseño gráfico, código fuente y demás elementos de software contenidos en la presente página Web), son propiedad de Aicad Business School, S.L. o de las demás entidades que puedan ofrecer información a través del Portal. Por tanto, están sujetos a derechos de propiedad intelectual e industrial protegidos por la legislación española e internacional.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Sin la previa autorización escrita de forma fehaciente de Aicad Business School, S.L. o, en su caso, de la entidad titular de los derechos, no está permitido utilizar, reproducir, transmitir, manipular, así como tampoco hacer cualquier uso que exceda la visita de la página y la utilización de los servicios ofrecidos.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              El acceso a los servicios suministrados no supone por parte de Aicad Business School, S.L., ni por parte del respectivo titular, renuncia, transmisión, ni cesión total o parcial de los derechos derivados de la propiedad intelectual e industrial, ni atribuye derechos de utilización, alteración, explotación, reproducción, distribución o comunicación pública sobre estos contenidos sin la previa y expresa autorización por escrito de los respectivos titulares de dichos derechos, sin perjuicio del derecho de visualización y obtención de copia privada de seguridad de tales contenidos, siempre que dicho derecho se ejercite según los principios de buena fe y siempre que se mantenga inalterada la propiedad intelectual e industrial del titular de tales derechos, se utilice sin fines comerciales y exclusivamente para información personal del usuario.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              La responsabilidad derivada del uso de materiales protegidos por los derechos de propiedad intelectual e industrial contenidos en esta página corresponderá exclusivamente al usuario.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">5.- OBJETO DEL PORTAL.</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              La presente página ha sido diseñada por Aicad Business School, S.L. para facilitar información sobre sus servicios y otras actividades de la empresa. Las páginas web disponen de unos datos de contacto y formularios a través de los cuales los usuarios pueden dirigirse a la empresa para las finalidades previstas en cada caso, según la regulación de las cláusulas del Portal.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              El mero acceso al Sitio Web, la cumplimentación de formularios, el envío de solicitudes de información, consultas, quejas, ofertas de contratación, curriculum vitae y, en general, cualquier acto de naturaleza similar a los anteriores realizados a través de los formularios y/o buzones electrónicos existentes en el Sitio Web implicará, por su parte, la aceptación sin reservas de todas y cada una de las normas integrantes del presente Aviso Legal y la adquisición de la consideración de Usuario del Sitio Web. En consecuencia, Vd. debe leer atentamente y conocer el contenido del presente Aviso Legal.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Aicad Business School, S.L. se reserva el derecho a realizar cuantas modificaciones y actualizaciones crea convenientes sobre la página web.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">6.- EXONERACIÓN DE RESPONSABILIDAD.</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Aicad Business School, S.L. no responderá por las interrupciones que puedan producirse en los servicios eléctricos o de telecomunicaciones que impidan a los usuarios utilizar los servicios ofrecidos. Aicad Business School, S.L. no responderá frente a cualquier posible responsabilidad por los daños y perjuicios de toda naturaleza que pudieran deberse a la falta de veracidad, vigencia, exhaustividad o autenticidad de la información facilitada por usuarios.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Asimismo, Aicad Business School, S.L. no responderá de las páginas Web a las que pueda remitir a través de enlaces o links, banners u otros dispositivos, ni de los contenidos que éstas pudieran incluir.
            </p>
          </div>
        </div>
        <div
          class="et_pb_module et_pb_text et_pb_text_2 et_pb_bg_layout_light  et_pb_text_align_left"
          style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background-image: initial; background-position: center center; background-size: cover; background-repeat: no-repeat; background-attachment: initial; background-origin: initial; background-clip: initial; vertical-align: baseline; text-size-adjust: 100%; overflow-wrap: break-word; animation-timing-function: linear; animation-duration: 0.2s; color: rgb(102, 102, 102); font-family: &quot;Open Sans&quot;, Helvetica, Arial, Lucida, sans-serif;"
        >
          <div
            class="et_pb_text_inner"
            style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; position: relative;"
          >
            <h3 style="box-sizing: border-box; margin: 0px; padding: 0px 0px 10px; border: 0px; outline: 0px; background: 0px 0px; font-size: 26px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(51, 51, 51); font-weight: 500; line-height: 1em; font-family: Oswald, Helvetica, Arial, Lucida, sans-serif;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL</span>
            </h3>
            <h3
              class="h3_each_static_section"
              style="box-sizing: border-box; margin: 0px; padding: 0px 0px 10px; border: 0px; outline: 0px; background: 0px 0px; font-size: 22px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(51, 51, 51); font-weight: 500; line-height: 1em; font-family: Oswald, Helvetica, Arial, Lucida, sans-serif;"
            >
              ¿Con qué finalidad tratamos sus datos personales?
            </h3>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              De conformidad con lo establecido en el Reglamento General de Protección de Datos (UE) 2016/679 (en adelante, RGPD) y demás normativa vigente en materia de protección de datos personales, le informamos de que sus datos personales son tratados en calidad de responsable del tratamiento por AICAD BUSINESS SCHOOL SL (en adelante, Aicad), con domicilio social en Calle León y Castillo 54, 1º Planta, 53003, Las Palmas de Gran Canaria.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">a) Información</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              La información recibida por Aicad Business School, S.L. a través de cualquiera de sus páginas Web será tratada con la máxima reserva y confidencialidad conforme a la normativa vigente.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Aicad Business School S.L. tratará sus datos personales con el fin de gestionar la relación que Usted mantiene con nosotros, así como para enviarle comunicaciones comerciales por cualquier vía, incluso finalizada la relación con usted, para mantenerle informado de aquellos de nuestros productos y servicios similares a los contratados por Usted.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">b) Envío y registro de datos de carácter personal</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              El envío de datos de carácter personal en esta web es obligatorio para contactar, comentar o contratar cualquier producto disponible para la venta en nuestra web.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Asimismo, el no facilitar los datos personales solicitados o el no aceptar la presente política de protección de datos supone la imposibilidad de suscribirse a contenidos y procesar las solicitudes realizadas en esta web.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              No es necesario que facilites ningún dato de carácter personal para la navegación por esta web.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Los casos en los que esta web requiere datos personales son:
            </p>
            <ul style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; list-style-position: initial; list-style-image: initial; line-height: 26px;">
              <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Para realizar la suscripción a la newsletter
              </li>
              <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Para comentar post publicados en el blog.
              </li>
              <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Para descargar algunos de los materiales que se ofrecen en el blog.
              </li>
              <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Para realizar consultas a través del formulario de contacto.
              </li>
              <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Para compartir contenidos en redes sociales.
              </li>
              <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Para aplicar a las ofertas de empleo.
              </li>
              <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Para contratar los servicios disponibles en esta web
              </li>
            </ul>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">c) Ejercicio de los derechos ARCO, el derecho al olvido y portabilidad<br style="box-sizing: border-box;"></span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Para ejercitar los derechos de acceso, rectificación, olvido, portabilidad, cancelación y oposición previstos en la Ley, puede dirigirse por escrito&nbsp;<span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">a Aicad Business School, S.L. Ref. Protección de datos, Calle León y Castillo 54, 1º Planta, 53003, Las Palmas de Gran Canaria o bien a la dirección de correo electrónico&nbsp;<a
                href="mailto:info@websincoste.com"
                style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(0, 160, 223); text-decoration-line: none;"
              >info@websincoste.com</a>.&nbsp;</span>En el caso de no existir alguna relación contractual,&nbsp;<span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; text-decoration-line: underline;">podrás borrar tu cuenta</span>&nbsp;siempre que quieras desde el panel de usuario, accediendo a tu perfil de cliente.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Aicad Business School, S.L. declara y garantiza que mantiene los niveles de seguridad de protección de datos personales conforme al Real Decreto 994/1999, de 11 de junio, relativo a las medidas de seguridad de los ficheros automatizados que contengan datos de carácter personal; que ha establecido todos los medios técnicos a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos facilitados por los usuarios, sin perjuicio de informar que las medidas de seguridad en Internet no son inexpugnables.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">EXACTITUD Y VERACIDAD DE LOS DATOS</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              El usuario es el único responsable de la veracidad y corrección de los datos que remita al portal (o a sus afiliados, partners y colaboradores) exonerando al Prestador de cualquier responsabilidad al respecto. Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y autenticidad de los datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados. El usuario acepta proporcionar información completa y correcta en el formulario de contacto o suscripción.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">ACEPTACIÓN Y CONSENTIMIENTO</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              La base legal para el tratamiento de sus datos personales es la ejecución y mantenimiento de la relación mantenida con Usted, así como el consentimiento expreso que, en su caso, nos haya facilitado para los tratamientos adicionales consistentes en el envío de comunicaciones comerciales propias y/o de terceros, la elaboración de perfiles comerciales y la cesión de dichos datos y perfiles con&nbsp;<em style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">las finalidades indicadas en el apartado “<span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">Envío y registro de datos de carácter personal</span>”</em>&nbsp;Anterior.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Para cualquier otro uso distinto de los expresamente permitidos, será necesario obtener el consentimiento previo por escrito del titular de los derechos de que se trate.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">La aceptación y consentimiento del interesado:</span>&nbsp;En aquellos casos donde para realizar una solicitud sea necesario cumplimentar un formulario y hacer un “click” en el botón de enviar, nuestros formularios cuentan con el símbolo * en los datos obligatorios. Si no facilita esos campos, o no marca el “checkbox” de aceptación de la política de privacidad, no se permitirá el envío de la información. Normalmente tiene la siguiente fórmula: “□ He leído y acepto la Política de privacidad y/o Protección de datos.”
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">SISTEMAS DE CAPTURA DE DATOS PERSONALES QUE UTILIZA ESTA WEB</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Esta web utiliza diferentes sistemas de captura de información personal. Esta web siempre requiere el consentimiento previo de los usuarios para tratar sus datos personales con los fines indicados.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              El usuario tiene derecho a revocar su consentimiento previo en cualquier momento.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Sistemas de captura de información personal que utiliza Aicad:
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              1)&nbsp; Formularios de suscripción a contenidos: dentro de la web existen varios formularios para activar la suscripción. Deberás confirmar tu suscripción para poder validar tu dirección de correo electrónico. Los datos facilitados serán utilizados exclusivamente para enviar la Newsletter y mantenerte actualizado sobre novedades y ofertas puntuales, exclusivas.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              2)&nbsp; Formulario de comentarios: La web incluye un formulario para comentar artículos, dejar su reseña para los productos y/o otros post del tipo personalizado. El usuario podrá publicar comentarios en los post que se publiquen. Los datos personales introducidos en el formulario para insertar estos comentarios serán utilizados exclusivamente para moderarlos y publicarlos.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              3)&nbsp; Formulario de contacto: También existe un formulario de contacto para consultas, sugerencias o contratación de servicio. En este caso se utilizará la dirección de correo electrónico para responder a las mismas y enviar la información que el usuario requiera a través de la web.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              4)&nbsp; Cookies: Cuando el usuario se registra o navega en esta web, se almacenan “cookies”, El usuario puede consultar en cualquier momento la política de cookies para ampliar información sobre el uso de cookies y como desactivarlas.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              5)&nbsp; Sistemas de Descargas: En esta web se pueden descargar diferentes contenidos que se incorporan periódicamente en nuestro blog, en otros portales pertenecientes a Aicad y los usuarios cuentan con la posibilidad de descargar en formato PDF cualquier temario del curso.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              6)&nbsp; Navegación y Dirección IP: Al navegar por esta web, el usuario facilita de forma automática al servidor de la web información relativa a tu dirección IP, fecha y hora de acceso, el hipervínculo que le ha reenviado a éstas, tu sistema operativo y el navegador utilizado. La Agencia Española de Protección de Datos considera la IP como dato de carácter personal. Esta web no utilizará la IP para identificar a sus usuarios, estos datos serán guardados y utilizados únicamente para el control y realización de estadísticas de acceso y visitas a la web, para analizar tendencias, administrar el sitio, y para recopilar información demográfica sobre nuestra base de usuarios en su conjunto. En ningún caso serán comunicados o cedidos a terceros.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">CORREOS COMERCIALES</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              De acuerdo con la LSSICE, Aicad no realiza prácticas de SPAM, por lo que no envía correos comerciales por vía electrónica que no hayan sido previamente solicitados o autorizados por el Usuario, en algunas ocasiones, puede remitir promociones y ofertas puntuales propias y de terceros, solo en los casos en los que cuente con la autorización de los destinatarios. En consecuencia, en cada uno de los formularios habidos en la Página Web, el Usuario tiene la posibilidad de dar su consentimiento expreso para recibir mi “Newsletter”, con independencia de la información comercial puntualmente solicitada. También podrá cancelar su suscripción de manera automática en la misma Newsletters..
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">MEDIDAS DE SEGURIDAD</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              De conformidad con la legislación vigente, el responsable de este sitio ha adoptado los niveles y medidas de seguridad para la protección de los datos personales que se establecen en el Reglamento General de Protección de Datos (UE) 2016/679 de protección de datos de carácter personal, respetando las obligaciones y el deber de confidencialidad que establece la ley, e instalando en sus sistemas y ficheros las medidas técnicas necesarias para garantizar el tratamiento confidencial de los datos, evitar su pérdida, alteración y acceso no autorizado.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">Política de protección de menores</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Quien facilita los datos a través de los formularios de esta Web y acepta su tratamiento declara formalmente ser mayor de 14 años. Queda prohibido el acceso y uso del portal a los menores de 14 años de edad.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Aicad recuerda a las personas mayores de edad que tengan a su cargo menores, que será de su exclusiva responsabilidad si algún menor incorpora sus datos para solicitar algún servicio.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              También les informa que existen programas informáticos para acotar la navegación mediante el filtro o bloqueo a determinados contenidos.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">LEGISLACIÓN APLICABLE</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Todos los litigios, controversias o reclamaciones surgidas de la interpretación o la ejecución de las condiciones generales de venta se regirán por la legislación española, y se someterán a la jurisdicción de los Juzgados y Tribunales del domicilio del titular del Web.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">CAMBIOS EN LA PRESENTE POLÍTICA DE PRIVACIDAD</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Aicad Business School se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria. En dichos supuestos, el Prestador anunciará en esta página los cambios introducidos con razonable antelación a su puesta en práctica.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">CONFIDENCIALIDAD</span>
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Tiene carácter confidencial toda la información y documentación utilizada durante la contratación, desarrollo y ejecución de las Condiciones contractuales que regulan las relaciones entre Aicad y el Cliente. No se entenderá información confidencial aquélla que sea divulgada por acuerdo entre las Partes, aquélla que se convierta en pública por igual motivo o aquélla que haya de ser revelada de acuerdo con las leyes o con una resolución judicial de autoridad competente y aquélla que sea obtenida por un tercero que no se encuentre bajo la obligación de confidencialidad alguna. Ambas partes se obligan a cumplir el deber de confidencialidad y a mantenerlo por un periodo mínimo de dos (2) años después de finalizar las mencionadas Condiciones contractuales que regulan las relaciones de Aicad y el Cliente.
            </p>
            <p style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
              Toda información recibida por parte del cliente ya sean imágenes, textos, datos de acceso como usuarios y contraseñas de WordPress, hosting u otros, se tratará de modo confidencial, estando totalmente prohibida la cesión a terceros a menos que contemos con su consentimiento y siempre para la misma finalidad en la que han sido obtenidos los datos.
            </p>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
    name: 'LegalModal'
};
</script>

<style lang="scss">

.vm--modal{
  overflow: visible !important;
  padding: 2%;
  left:  0px !important;
  top: 2% !important;
  width: 50% !important;
  margin: auto !important;
  padding-right: 0px;
  padding-left: 0px;
}
.vm--modal  h2 {
    font-size: 1.5rem !important;
    text-align: center;
}
.vm--modal  p {
  font-size:0.7rem !important;
  text-align: justify;
}
.vm--modal .h3{
  font-size: 1.5rem;
}

@media screen and (min-width: 3000px) {
  .vm--modal  h2 {
      text-align: center;
      font-size: xx-large !important;
  }

  .vm--modal  p {
    font-size:xx-large !important;
    text-align: justify;
  }

  .vm--modal h3{
    font-size: xx-large !important;
  }
}

@media screen and (max-width: 500px) {
  .vm--modal{
    overflow: visible !important;
    padding: 2%;
    left:  0px !important;
    top: 2% !important;
    width: 90% !important;
    margin: auto !important;
    padding-right: 0px;
    padding-left: 0px;
  }

  .vm--modal  h2 {
      text-align: center;
      font-size: 1.5rem !important;
  }

  .vm--modal  p {
    font-size:smaller !important;
    text-align: justify;
  }

  .vm--modal h3{
    font-size: 1.3rem !important;
  }
}
</style>
