<template>
  <div>
    <div v-if="!sentSuccess">
      <form
        id="fllamada"
        method="POST"
        action="#"
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col
            md="6"
            sm="12"
            class="form-country"
          >
            <b-form-input
              id="nombre"
              v-model="form.nombre"
              class="mt-1"
              name="nombre"
              type="text"
              :placeholder="$t('masteresPosgrados.openDay.llamada.fnombre')"
              required
            />
          </b-col>
          <b-col
            md="6"
            sm="12"
            class="form-country"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              class="mt-1"
              name="email"
              type="email"
              :placeholder="$t('masteresPosgrados.openDay.llamada.femail')"
              required
            />
          </b-col>
          <b-col
            md="3"
            sm="12"
            class="form-country"
          >
            <b-form-select
              id="pais"
              v-model="form.pais"
              class="mt-1"
              name="pais"
              :options="fpais"
              required
            />
          </b-col>
          <b-col
            md="9"
            sm="12"
            class="form-country"
          >
            <b-row>
              <b-col cols="4">
                <b-form-select
                  id="code"
                  v-model="form.code"
                  class="mt-1"
                  name="code"
                  :options="code"
                  required
                />
              </b-col>
              <b-col cols="8">
                <b-form-input
                  id="sms"
                  v-model="form.sms"
                  class="mt-1"
                  name="sms"
                  type="number"
                  :placeholder="$t('masteresPosgrados.openDay.llamada.fsms')"
                  required
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            sm="12"
            class="form-country"
          >
            <b-form-select
              id="campus"
              v-model="form.campus"
              class="mt-1"
              name="campus"
              :options="fcampus"
              required
            />
          </b-col>
          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <textarea
                v-model="form.comment"
                rows="8"
                class="form-control"
                maxlength="500"
                name="COMENTARIOS"
                placeholder="Cuéntanos en qué te podemos ayudar....."
              />
            </div>
          </div>
          <div style="padding-bottom:16px">
            <div
              class="sib-form-block"
              style="font-size:12px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#3C4858; background-color:transparent; border-width:px;"
            >
              <div class="sib-text-form-block">
                <p>
                  Puedes &nbsp;consultar la información adicional y detallada sobre Protección de Datos en nuestra <a
                    style="cursor:pointer; color: rgb(4, 101, 245);"
                    @click="showModal()"
                  >política de privacidad</a>.&nbsp;
                </p>
                <p><strong>Responsable del tratamiento</strong>: Aicad Business School S.L.</p>
                <p><strong>Finalidad de los datos</strong>: Envío de boletines de noticias y ofertas.</p>
                <p><strong>Almacenamiento de los datos</strong>: Base de datos alojada en la UE, y con transferencias internacionales de gestión a terceros paises.</p>
                <p>
                  <strong>Derechos</strong>: En cualquier momento puedes
                  <a 
                    style="cursor:pointer; color: rgb(4, 101, 245);" 
                    @click="showModal()"
                  >limitar, recuperar, rectificar, suprimir y borrar tu información.</a>
                </p>
              </div>
            </div>
          </div>
          <b-col
            md="6"
            sm="12"
            class="pt-1 text-left form-country"
          >
            <b-form-checkbox
              id="aceptar"
              v-model="form.aceptar"
              name="aceptar"
              class="mt-1"
              value="1"
              unchecked-value="0"
              @change="aceptPolitica"
            >
              <span>{{ $t('masteresPosgrados.openDay.llamada.facept1') }}</span>
              <a
                :href="url"
                target="_blank"
              >{{ $t('masteresPosgrados.openDay.llamada.facept2') }}</a>
            </b-form-checkbox>
          </b-col>
          <b-col
            md="6"
            sm="12"
            class="pt-1 text-right form-country"
          >
            <b-button
              class="buttonContacto"
              type="submit"
              :disabled="enviar == 0"
            >
              {{ $t('masteresPosgrados.openDay.llamada.btnEnviar') }}
              <font-awesome-icon
                :icon="['fas', 'angle-right']"
                class="ml-2"
                size="lg"
              />
            </b-button>
          </b-col>
        </b-row>
      </form>
    </div>
    <div
      v-if="sentSuccess"
      class="send-success"
    >
      <h2>{{ $t('common_contact_form.thanks_message') }}</h2>
      <h3>
        {{ $t('common_contact_form.will_contact') }}
      </h3>
      <div
        class="contact-info text-center mt-4"
        style="color:#fff"
      >
        <h4>Puedes contactarme por</h4>
        <div class="contact-nfo">
          <font-awesome-icon
            icon="phone"
            class="phone-icon"
          />
          <a
            href="tel:+34928354668"
            style="color:#fff"
          >+34 928 354 668</a>
        </div>
      </div>
    </div>
    <LegalModal modalinfo="modal-info" />
  </div>
</template>
<style scoped lang="scss">
.form-country {
	padding: 15px 10px;
}
div.send-success {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	height: 100%;
}

#sib-form div {
	color: #fff;
}
#sib-form div,
#sib-form h1,
#sib-form p,
#sib-form label,
#sib-form span {
	color: #fff !important;
}
.sib-form {
	background-color: transparent !important;
	padding: 16px 12px !important;
}
#sib-container {
	background-color: rgba(0, 0, 0, 0) !important;
	border: none !important;
	padding: 10px !important;
}
#sib-form option,
#sib-form select,
#sib-form input {
	color: #000;
}
#sib-form input::placeholder {
	color: #000;
	padding-left: 10px;
}
.sib-text-form-block p {
	font-size: 1.4vh !important;
}
.sib-optin.sib-form-block {
	padding: 0 40px;
}
p {
  margin-top: 0;
  margin-bottom: 0rem;
}


@media screen and (min-width: 3800px) {
  .sib-text-form-block p {
    font-size: 0.8vh !important;
  }
}

@media screen and (min-width: 7600px) {
  .sib-text-form-block p {
    font-size: 0.34vh !important;
  }
}

</style>
<script>
import LegalModal from '@/components/Reusable/LegalModal-info.vue';
export default {
    components: {
        LegalModal
    },
    data() {
        return {
            form: {
                nombre: null,
                email: null,
                apellido1: null,
                apellido2: null,
                pais: 'España',
                code: '+34',
                sms: null,
                campus: null,
                comment: '',
                master: null,
                aceptar: 0,
                web: null
            },
            sentSuccess: false,
            sentFailed: false,
            enviar: 0,
            code: [
                { value: '+34', text: '+34 ES' }
            ],
            url: '/aviso-legal-privacidad/',
            modalVisible: false
        };
    },
    computed: {
        fpais() {
            return this.$t('masteresPosgrados.openDay.llamada.fpais');
        },
        fcampus() {
            return this.$t('masteresPosgrados.openDay.llamada.fcampus');
        },
        fmaster() {
            return this.$t('masteresPosgrados.openDay.llamada.fmaster');
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.enviar = 0;
            this.axios
                .post('https://www.websincoste.com/index.php', {
                    _nonce: 'f9d8d3d927baca5e53d95c32b41f6f00',
                    action: 'send_universal_form',
                    serialize: [
                        { name: 'Form Type', value: 'Masteres Posgrados Modal Form' },
                        { name: 'Nombre y apellido', value: this.form.nombre },
                        { name: 'Email', value: this.form.email },
                        // { name: 'Apellido 1', value: this.form.apellido1 },
                        // { name: 'Apellido 2', value: this.form.apellido2 },
                        { name: 'Pais', value: this.form.pais },
                        { name: 'Code', value: this.form.code },
                        { name: 'SMS', value: this.form.sms },
                        { name: 'Campus', value: this.form.campus },
                        { name: 'Comment', value: this.form.comment },
                        { name: 'Master', value: this.form.master },
                        { name: 'Reference Link', value: window.location.href },
                        { name: 'Aceptación Privacidad RGDP:', value: this.form.aceptar }
                    ]
                })
                .then(response => {
                    if (response.status === 200) {
                        this.sentSuccess = true;
                        this.form = {
                            nombre: null,
                            email: null,
                            apellido1: null,
                            apellido2: null,
                            pais: null,
                            code: '+34',
                            sms: null,
                            campus: null,
                            comment: '',
                            master: null,
                            aceptar: null,
                            web: null
                        };
                        setTimeout(() => {
                            this.sentSuccess = false;
                        }, 10000);
                    } else {
                        this.sentFailed = true;
                        let send = this.$t('sendERROR');
                        this.$bvToast.toast(send, {
                            title: this.$t('message.rs'),
                            variant: 'default',
                            autoHideDelay: 3000,
                            solid: true
                        });
                    }
                })
                .catch(error => {
                    this.sentFailed = true;
                    this.sentSuccess = false;
                    console.log(error);
                });
        },
        aceptPolitica(checked) {
            this.enviar = checked == 1;
        },
        showModal: function() {
            this.$modal.show('modal-info');
        },
        hide() {
            this.$modal.hide('modal-info');
        }
    }
};
</script>
