<template>
  <div class="banner">
    <b-container>
      <b-row>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <h2 class="h2">
            {{ country }}
          </h2>
          <div
            class="hr"
            style="height: 2px !important; background: white"
          />
          <h3 class="h1-medium">
            {{ desc1 }}
          </h3>
          <h3 class="h1-medium">
            {{ desc2 }}
          </h3>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <div class="text-container">
            <Llamada />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Llamada from './CountriesForm';
export default {
    name: 'Banner',
    components: {
        Llamada
    },
    props: {
        country: {
            default: '',
            type: String
        },
        desc1: {
            default: '',
            type: String
        },
        desc2: {
            default: '',
            type: String
        },
        img: {
            default: '',
            type: String
        },
        phone: {
            default: '',
            type: String
        },
        address: {
            default: '',
            type: String
        },
        email: {
            default: '',
            type: String
        }
    },
    data() {
        return {
            imgPath: require('../../../assets/img/franquicias_exito/Photos/Barcelona.png')
        };
    },
    methods: {
        getURL() {
            return this.imgPath;
        }
    }
};
</script>

<style scoped lang="scss">
.foto {
	max-width: 200px;
}
.banner {
	padding: 50px 0;
	background: rgb(0, 161, 217);
	background: linear-gradient(151deg, rgba(0, 161, 217, 1) 0%, rgba(85, 190, 185, 1) 43%, rgba(0, 95, 115, 1) 90%);

	h2 {
		color: white;
		font-size: 5vh !important;
		@media only screen and (min-width: 1280px) {
			font-size: 6vh !important;
		}
	}

	h3 {
		color: whitesmoke;
		font-size: 2.5vh !important;
		@media only screen and (min-width: 1280px) {
			font-size: 3vh !important;
		}
	}



@media screen and (min-width: 3800px) {
	h2 {
		font-size: 4vh !important;
	}

	h3 {
		font-size: 2vh !important;
	}
}

@media screen and (min-width: 7600px) {
	h2 {
		font-size: 3vh !important;
	}

	h3 {
		font-size: 1vh !important;
	}
}

	.text-container {
		display: block;
		text-align: center;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		padding: 50px 20px;
		border-top: #2a89b9 10px solid;
		background-color: white;
		-webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

		h2 {
			font-family: LatoWebMedium, sans-serif;
			font-size: 1rem;
			color: #4b4b4b;
		}

		h3 {
			font-family: LatoWebLight, sans-serif;
			font-size: 1rem;
		}
	}
}
</style>
